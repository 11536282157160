// extracted by mini-css-extract-plugin
export var alignDiscLeft = "m_pY d_fn d_bF d_dt";
export var alignLeft = "m_pL d_fn d_bF d_dt";
export var alignDiscCenter = "m_pZ d_fp d_bC d_dv";
export var alignCenter = "m_bN d_fp d_bC d_dv";
export var alignDiscRight = "m_p0 d_fq d_bG d_dw";
export var alignRight = "m_pM d_fq d_bG d_dw";
export var footerContainer = "m_p1 d_dV d_bV";
export var footerContainerFull = "m_p2 d_dS d_bV";
export var footerHeader = "m_kd d_kd";
export var footerTextWrapper = "m_p3 d_v";
export var footerDisclaimerWrapper = "m_kl d_kl d_cj";
export var badgeWrapper = "m_p4 d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "m_p5 undefined";
export var footerDisclaimerLeft = "m_p6 undefined";
export var verticalTop = "m_p7 d_by d_bH d_bM d_bK";
export var firstWide = "m_p8";
export var disclaimer = "m_p9 d_by d_bH";
export var socialDisclaimer = "m_qb";
export var left = "m_qc";
export var wide = "m_qd d_cx";
export var right = "m_qf d_bJ";
export var line = "m_fj d_fk d_ct";
export var badgeDisclaimer = "m_qg d_bB d_bN d_bH";
export var badgeContainer = "m_qh d_by d_bG d_bN";
export var badge = "m_qj";
export var padding = "m_qk d_c6";
export var end = "m_ql d_bG";
export var linkWrapper = "m_qm d_dz";
export var link = "m_mB d_dz";
export var colWrapper = "m_qn d_cw";
export var consent = "m_f d_f d_bB d_bN";
export var media = "m_qp d_bw d_dx";
export var itemRight = "m_qq";
export var itemLeft = "m_qr";
export var itemCenter = "m_qs";
export var exceptionWeight = "m_qt q_rs";